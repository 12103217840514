import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un mamelló al capell de color marró rogenc, dissociat en petites esquames repartides per la resta del capell. Aquest mesura de 2,6 a 6 cm de diàmetre, primer acampanat per passar a aplanat i de color blanquinós o un poc rogenc. Les nombroses làmines que té són blanques, lliures i un poc separades del peu, deixant un collarí que l’envolta. El peu és fibrós i llarg, de color blanc a un poc rogenc presentant a la part inferior esquames blanques amb marge rogenc. L’anell queda reduït a unes esquames rogenques i un poc cotonoses, que amb l’edat desapareixen. Les espores són blanques en massa, el·líptiques, de 6-8 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      